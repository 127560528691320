import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function PDFGenerator({ postOrderResponse, suppliers, pickUpLocations, products }) {
  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Set font size and style
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');

    // Document Title
    doc.text(`Order IO-${postOrderResponse.id}`, 14, 20);

    // Order Information
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    // Fetch supplier and location names
    const supplier = suppliers.find(sup => sup.id === postOrderResponse.supplier_id);
    const location = pickUpLocations.find(loc => loc.id === postOrderResponse.location_id);

    const orderInfo = [
      ['Order Reference:', `IO-${postOrderResponse.id}`],
      ['Supplier:', supplier ? supplier.name : 'Unknown'],
      ['Pick-up Location:', location ? location.name : 'Unknown'],
      ['Order Date:', postOrderResponse.date_order],
      ['Arrival Date:', postOrderResponse.date_arrival],
    ];

    // Add Order Information to PDF
    doc.autoTable({
      startY: 30,
      headStyles: { fillColor: [41, 128, 185] },
      body: orderInfo,
      theme: 'grid',
      styles: { fontSize: 11 },
    });

    // Space before next section
    let finalY = doc.lastAutoTable.finalY + 10;

    // Table Headers
    const tableColumn = ['Art. Nr', 'Name', 'Cartons', 'Price', 'Currency'];

    // Map data for table rows
    const tableRows = postOrderResponse.lines.map(line => {
      const product = products.find(prod => prod.id === line.product_id);
      return [
        product ? product.article_nr : 'Unknown',
        product ? product.name : 'Unknown',
        line.quantity_cartons,
        line.assumed_carton_price,
        line.assumed_currency,
      ];
    });

    // Add Table to PDF
    doc.autoTable({
      startY: finalY,
      head: [tableColumn],
      body: tableRows,
      headStyles: { fillColor: [41, 128, 185] },
      styles: { fontSize: 11 },
    });

    // Add Comments
    finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Comments:', 14, finalY);

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.text(`Comment: ${postOrderResponse.comment_external || 'None'}`, 14, finalY + 10);

    // Save the PDF
    doc.save(`Order_IO-${postOrderResponse.id}.pdf`);
  };

  return (
    <div>
      <a href="#" onClick={generatePDF}>
        Download PDF
      </a>
    </div>
  );
}

export default PDFGenerator;
