import React from "react";
import { useEffect, useState } from "react";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import { Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import FilterMultipleSelect from "../../components/FilterMultipleSelect";

export default function SalesVolume(){
    const [salesVolume, setSalesVolume] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [selectedProducts, setSelectedProducts] = React.useState([]);
    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    const [salesVolumeFilter, setSalesVolumeFilter] = React.useState("0");
    const [endpoints, setEndpoints] = React.useState(["GetSumOutboundOrdersLast21Days", "GetSumOutboundOrdersLast16Weeks", "GetSumOutboundOrdersLast12Months"])
    const [list1, setList1] = React.useState([]);
    const [list2, setList2] = React.useState([]);
    const [list3, setList3] = React.useState([]);
    const [currentList, setCurrentList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [intervals, setIntevals] = React.useState(["day", "week", "month"])
    const [error, setError] = React.useState({
        status: false,
        message: "Det har oppstått en feil, prøv igjen senere."
    })
    const dfd = require("danfojs")

    const fetchSalesVolume = async (listType) =>{ 
        setLoading(true);       
        const response = await fetch(`./api/${endpoints[listType]}`, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            credentials: 'include'
        })
        if (response.status == 200)
        {
            const data = await response.json();                                      
            if (listType > 0){     
                setCurrentList(data.result)           
                return data.result;
            }                                  
            return data.result;
        }
        else
        {            
            setError(prevError => {
                return{
                    ...prevError,
                    status: true                    
                }
            })
            setCurrentList([]);
            return [];
        }        
    }
    
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])
    
     React.useEffect(() =>
    {                                       
        if (!token) return;
        setLoading(true);
        fetch("./api/GetAllProducts",{
            headers:{
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then(response =>{
            if (response.status == 200){
                return response.json();
            }
            else{
                setError(prevError => {
                    return{
                        ...prevError,
                        status: true                    
                    }
                })
            }
        })
        .then((data) => {                        
            setProducts(data);
            console.log(data)
        })        
        setLoading(false);
        async function fetchData(){
            const salesvolumeList = await fetchSalesVolume(salesVolumeFilter);                                
            setList1(salesvolumeList);
            setCurrentList(salesvolumeList);                 
            setLoading(false);       
        }
        fetchData();

    },[token])

    async function handleFilterchange(event){               
            const {value} = event.target    // kan erstattes med løkke. alle lister bli satt i en list og så iterer vi gjennom yttre liste
            setCurrentList([]);
            setSalesVolumeFilter(value);            
            if (value == 0){
                if (!list1.length){
                    const newList = await fetchSalesVolume(value);
                    if (newList.length > 0){
                        setList1(newList);                    
                        setCurrentList(newList);
                    }   
                    setLoading(false); 
                }
                else{                    
                    setCurrentList(list1);                    
                }
            }
            else if (value == 1){                
                if (!list2.length){                               
                    const newList = await fetchSalesVolume(value);
                    if (newList.length > 0){         
                        setList2(newList);                    
                        setCurrentList(newList); 
                    }   
                    setLoading(false);  
                }
                else{                    
                    setCurrentList(list2);
                }
            }
            else if (value == 2){
                if (!list3.length){                    
                    const newList = await fetchSalesVolume(value);
                    if (newList.length > 0){
                        setList3(newList);                    
                        setCurrentList(newList);
                    }  
                    else{console.log("en feil, men håndtert riktig");}
                    setLoading(false);    
                }
                else{                    
                    setCurrentList(list3);
                }
            }                                            
            //setSalesVolumeFilter(event.target.value);
    }    
    
    function handleExport(){
        if (currentList.length > 0){
        let sheetData = [];
        if (salesVolumeFilter == 0){
        for (let i = 0; i < currentList.length; i++){
            let myObj = {};
            myObj['name'] = currentList[i].productName;
            myObj['artnr'] = currentList[i].productArtNr;
            myObj['In stock'] = currentList[i].inventory;
            myObj['AVG per ' + intervals[salesVolumeFilter]] = currentList[i].avgSold;
            myObj['Days of inventory'] = currentList[i].doi
            currentList[i].data.forEach(d => {
                myObj[d.date.split(" ")[0]] = d.quantity;                
            })
            sheetData.push(myObj);
        }
        var df = new dfd.DataFrame(sheetData);
        df.print();
        const csv = dfd.toExcel(df, { fileName: "sales.xlsx", download: true})
    }
    else{
        for (let i = 0; i < currentList.length; i++){
            let myObj = {};
            myObj['name'] = currentList[i].productName;
            myObj['artnr'] = currentList[i].productArtNr;
            myObj['In stock'] = currentList[i].inventory;
            myObj['AVG per ' + intervals[salesVolumeFilter]] = currentList[i].avgSold;
            myObj['Days of inventory'] = currentList[i].doi
            currentList[i].data.forEach(d => {
                myObj[d.date] = d.quantity;                
            })
            sheetData.push(myObj);
        }
        var df = new dfd.DataFrame(sheetData);
        df.print();
        const csv = dfd.toExcel(df, { fileName: "sales.xlsx", download: true})
    }
    }
    }

    return(
        <div>
            <AuthenticatedTemplate>
            <Container className="mt-5">
                <Row>
                <Col className="d-flex align-items-center">
                    <h2 className="my-0">Sales volume</h2>                                                           
                </Col>                
                {error.status && <p>{error.message}</p>} 
                </Row>
                { !error.status &&
                <Row className="mt-4">
                <p style={{padding: 0}}>Select filter for sales volume</p>
                <Form.Select
                aria-label="Default select example"
                value={salesVolumeFilter}
                onChange= {handleFilterchange}
                >                
                <option value="0">Last 21 days</option>
                <option value="1">Last 16 weeks</option>
                <option value="2">Last 12 months</option>
                </Form.Select>
                </Row>}
                { !error.status &&
                <Row className="mt-4">
                    <div style={{padding:0}}>
                    <Button variant="secondary" onClick={handleExport}>Export as excel file</Button>                
                    </div>
                </Row>}
                
                { !error.status &&
                <Row className="mt-4">
                <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)}></FilterMultipleSelect>                        
                    </Card.Header> 
                    <Card.Body className="p-0 m-0">
                    <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                            height="80" 
                            width="80" 
                            radius="9"
                            color="#3e3e3e" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={loading}
                            />
                        </div>
                        { currentList.length > 0 &&
                            <Table striped bordered hover responsive className="m-0">
                                <thead>
                                    <tr>                                    
                                    <th className="fw-bold">Name</th>
                                    <th className="text-center fw-bold">Art nr</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>In stock</th>
                                    <th className="text-center fw-bold w-auto" style={{whiteSpace: 'nowrap'}}>AVG per {intervals[salesVolumeFilter]}</th>   
                                    <th className="text-center fw-bold"> {intervals[salesVolumeFilter]}s of inventory</th>                                   
                                    { salesVolumeFilter == 0 &&                                       
                                        currentList[0].data.map(d => {
                                        return <th style={{whiteSpace: 'nowrap'}} className="w-auto text-center fw-bold">{d.date.split(" ")[0]}</th>
                                       })
                                    }
                                    {
                                        salesVolumeFilter == 1 &&
                                        currentList[0].data.map(d => {
                                            return <th style={{whiteSpace: 'nowrap'}} className="w-auto text-center fw-bold">{d.date}</th>
                                           })
                                    }
                                    {
                                        salesVolumeFilter == 2 &&
                                        currentList[0].data.map(d => {
                                            return <th style={{whiteSpace: 'nowrap'}} className="w-auto text-center fw-bold">{d.yearMonth}</th>
                                           })
                                    }
                                    </tr>
                                </thead>
                                <tbody style={{"cursor": "pointer"}}>
                                    { selectedProducts.length == 0 && 
                                        currentList.map(p => {
                                            return(
                                                <tr>                      
                                                    <td className="px-2 text-center">{p.productArtNr}</td>
                                                    <td className="px-2 text-left">{p.productName}</td>
                                                    <td>{p.inventory}</td>
                                                    <td>{p.avgSold}</td>
                                                    <td>{p.doi}</td>
                                                    {
                                                        p.data.map(q => {
                                                            return(
                                                                <td>{q.quantity}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                    { selectedProducts.length > 0 &&
                                        currentList.filter(item => {
                                            for (var prod in selectedProducts){
                                                if (selectedProducts[prod].id == item.productId){                                                
                                                    return true;
                                                }                                            
                                            }
                                            return false;
                                        }).map(p => {
                                            return(
                                                <tr>                                                    
                                                    <td>{p.name}e</td>
                                                    <td>{p.productArtNr}</td>
                                                    <td>{p.inventory}</td>
                                                    <td>{p.avgSold}</td>
                                                    <td>{p.doi}</td>
                                                    {
                                                        p.data.map(q => {
                                                            return(
                                                                <td>{q.quantity}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                    </Card.Body>
                </Card>
                </Row>}
            </Container>
            </AuthenticatedTemplate>
        </div>
    )
}