import { InfinitySpin } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { InteractionType } from '@azure/msal-browser';
import Table from "react-bootstrap/Table";
import NavigationBar from "../../modules/NavigationBar";
import { Navbar, Nav, Row, Container, Button} from "react-bootstrap"
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import OrderListElement from "../../components/OrderListElement";
import { loginRequest } from "../../util/authConfig";
import Spinner from 'react-bootstrap/Spinner';



export default function Dashboard(props) {
    const [token, SetToken] = useState();
    const { instance, accounts } = useMsal();

    const [loading, setLoading] = useState(true);
    const [maxPage, SetMaxPage] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])


    const getOrderSegment = async () => {
        if(!token) return;
        setLoading(true);
        SetMaxPage(false);
        await fetch("./api/GetOrdersForUser", {
            method: "POST",
            body: JSON.stringify({
                "page": currentPage,
                "pageSize": perPage
            }),
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            })
        })
        .then(res => res.json())
        .then(data => {
            setOrders(data);
            setLoading(false);
            SetMaxPage(false);
        })
        .catch(err => {
            console.log("error ran")
            console.log(err);
            SetMaxPage(true);
            setLoading(false);
        })
    }
    useEffect(() => {
        if(!token) return;
        getOrderSegment();      
    },[token])


    

    return(
        <>
            <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect}>
            <Container className="mt-5">
                <Row>
                    <h1>Welcome, {accounts[0]?.name}!</h1>
                </Row>
            </Container>
            
            
               
            <Container className="my-5">
                <Row>

                    <NavLink className="nav-item nav-link-custom" to="/orderfrozen"><Button variant="secondary">Order frozen goods</Button></NavLink>
                    <NavLink className="nav-item nav-link-custom mt-2" to="/orderdry"><Button variant="secondary">Order dry goods</Button></NavLink>
                </Row>
            </Container>

            <Container>
                <Row>
                    <h2>Last orders</h2>
                    {loading &&
                            <div className="d-flex justify-content-center align-items-center">
                                <Spinner
                                height="80" 
                                width="80" 
                                radius="9"
                                color="#3e3e3e" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                                />
                            </div>
                        }
                        {!loading && !maxPage &&

                    <Table>
                        <thead>
                            <tr>
                                <th className="text-center">Order ID</th>
                                <th className="text-center">Order date</th>
                                <th className="text-center">Pick-up date</th>
                                <th className="px-2">Customer</th>
                                <th className="px-2">Sub Customer</th>
                                <th className="text-center"># Order Lines</th>
                                <th className="text-center">Export</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                    
                        
                        <>
                            {
                            orders.sort((a, b) => b.id - a.id).map((o) => {
                                return <OrderListElement o={o} Key={o.id} />
                            })
                            
                            }
                        </>
                        </tbody>
                    </Table>
                }

                </Row>
            </Container>

            <div className="d-flex justify-content-center align-items-center flex-column mt-5">
                <div className="position-fixed fixed-bottom text-center">
                    <p className="text-secondary">Application Version 23.07.28.1</p>
                </div>
            </div>
            
            </MsalAuthenticationTemplate>
        </>
    )
}