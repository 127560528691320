import React from "react";
import { useEffect, useState } from "react";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import { Accordion, Button, Card, Modal, Table, Toast, ToastContainer, Form, Row, Container, Col, Badge} from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import FilterMultipleSelect from "../../components/FilterMultipleSelect";
import FilterMultipleWithLabels from "../../components/FilterMultipleWithLabels";
import PDFGenerator from './PDFGenerator';


export default function InventoryPlanning(){
    const filterByFields = ['backstube_name', 'article_nr', 'gtin'];
    const filterByLabels = ['Name', 'Article nr.', 'gtin'];
    const [inventoryPlanning, setInventoryPlanning] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [suppliers, setSuppliers] = React.useState([]);
    const [pickUpLocations, setPickUpLocations] = React.useState([]);
    const [selectedProducts, setSelectedProducts] = React.useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = React.useState([]);
    const [selectedPickUpLocations, setSelectedPickUpLocations] = React.useState([]);
    const [salesVolumeFilter, setSalesVolumeFilter] = React.useState("0");
    const [currentList, setCurrentList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [inboundOrders, setInboundOrders] = React.useState([]); 
    const [error, setError] = React.useState({
        status: false,
        message: "Det har oppstått en feil, prøv igjen senere."
    })
    const [selectedOption, setSelectedOption] = useState("All"); // Default to "All"

    const [resultModal, setResultModal] = React.useState(false);
    const handleCloseResultModal = () => setResultModal(false);

    const [postOrderResponse, setPostOrderResponse] = React.useState();
    const [postOrderSuccess, setPostOrderSuccess] = React.useState(false);

    const handleChange = (event) => {
        setSelectedOption(event.target.value); // Update state with selected value
    };
    const [filterActive, setFilterActive] = useState(true)
    function handleFilterActive(){
        setFilterActive(!filterActive);
    }
    const dfd = require("danfojs")

    const [orderList, setOrderList] = React.useState([]);
    const [orderModalOpen, setOrderModalOpen] = React.useState(false);
    const handleCloseOrderModal = () => setOrderModalOpen(false);
    const [orderSummary, setOrderSummary] = useState({
        "suppliers": 0,
        "locations": 0,
        "articles": 0,
        "units": 0,
        "cartons": 0,
        "pallets": 0,
        "rak": 0,
        "transport": 0,
        "price": 0,
        "leadtime": 0,
        "supplier_id_guess": 0,
        "location_id_guess": 0
    })
    const [externalComment, setExternalComment] = useState("");
    const [internalComment, setInternalComment] = useState("");

    const { instance, accounts } = useMsal();
    const [token, SetToken] = useState();
    useEffect(() => {
        instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then((response) => {
                            SetToken(response.accessToken);
                        })
    }, [])



    const getInboundOrders = async () => {
        if(!token) return;
        fetch("./Api/GetAllInboundOrders", {
            headers: new Headers({
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            }),
            credentials: 'include'
        })
        .then(res => {
            if (res.status == 200){
                return res.json();
            } else {
            }
        })
        .then(data => {
            setInboundOrders(data);
        })
        .catch(err => {
            console.error('Fetch error:', err);
        })
    }


    const getInventoryPlanning = async () => {
        if(!token) return;
        fetch("./api/GetInventoryPlanning", {
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }),
            credentials: 'include'
        })
        .then(res => res.json())
        .then(data => {
            for (var p in data) {
                data[p].orderQuantity = ""
                data[p].orderQuantityPallets = ""
            }
            setInventoryPlanning(data);
        })
        .catch(err => {
        })
    }
    const getAllProducts = async () => {
        fetch("./api/GetAllProducts",{
            headers:{
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then(response =>{
            if (response.status == 200){
                return response.json();
            }
            else{
                setError(prevError => {
                    return{
                        ...prevError,
                        status: true                    
                    }
                })
            }
        })
        .then((data) => {                        
            setProducts(data);

            // var updatedOrder = [];
            // for (var p in data){
            //     var newOrderLine = {'id': p.id, 'quantity': 0}
            //     updatedOrder.push(newOrderLine);
            // }
            // setInboundOrder(updatedOrder);
        })        
    }
    const getAllSuppliers = async () => {
        fetch("./api/GetAllSuppliers",{
            headers:{
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then(response =>{
            if (response.status == 200){
                return response.json();
            }
            else{
                setError(prevError => {
                    return{
                        ...prevError,
                        status: true                    
                    }
                })
            }
        })
        .then((data) => {                        
            setSuppliers(data);

            
        })        
        setLoading(false);
    }
    const getAllPickupLocations = async () => {
        fetch("./api/GetAllPickupLocations",{
            headers:{
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
            },
            credentials: 'include'
        })
        .then(response =>{
            if (response.status == 200){
                return response.json();
            }
            else{
                setError(prevError => {
                    return{
                        ...prevError,
                        status: true                    
                    }
                })
            }
        })
        .then((data) => {                        
            setPickUpLocations(data);

            
        })        
    }

    function updateOrderSummary(){
        let invPlanCopy = JSON.parse(JSON.stringify(inventoryPlanning));
        var newOrderList = invPlanCopy.filter(function (s) {
            if(s.orderQuantityPallets == 0 || s.orderQuantityPallets == ""){
                return false;
            }
            else {
                return true
            }
        })

        var newOrderSummary = {
            "suppliers": 0,
            "locations": 0,
            "articles": 0,
            "units": 0,
            "cartons": 0,
            "pallets": 0,
            "rak": 0,
            "transport": 0,
            "price": 0,
            "leadtime": 0,
            "supplier_id_guess": 0,
            "location_id_guess": 0
        }
        
        let supplierList = []
        let locationList = []
        for (var o in newOrderList){
            if(!supplierList.includes(newOrderList[o].supplier_name)){
                supplierList.push(newOrderList[o]['supplier_name'])
            }
            if(!locationList.includes(newOrderList[o].pick_up_location_name)){
                locationList.push(newOrderList[o]['pick_up_location_name'])
            }
            if (newOrderSummary.leadtime < newOrderList[o].order_lead_time_in_days){
                newOrderList['leadtime'] = newOrderList[o].order_lead_time_in_days
            }

            newOrderSummary["supplier_id_guess"] = newOrderList[o]["supplier_id"]
            newOrderSummary["location_id_guess"] = newOrderList[o]["pickup_location_id"]

            newOrderSummary["articles"] += 1
            newOrderSummary["units"] += newOrderList[o]['orderQuantity'] * newOrderList[o]['pieces_per_quantity']
            newOrderSummary["cartons"] += newOrderList[o]['orderQuantity']
            newOrderSummary["pallets"] += newOrderList[o]['orderQuantityPallets']
            newOrderSummary["rak"] += newOrderList[o]['orderQuantity'] * newOrderList[o]["avg_råk_per_carton"]
            newOrderSummary["transport"] += newOrderList[o]['orderQuantity'] * newOrderList[o]["avg_transport_cost_per_carton"]
            newOrderSummary["price"] += newOrderList[o]['orderQuantity'] * newOrderList[o]["avg_purchase_price_per_carton"]
        }
        newOrderSummary['suppliers'] = supplierList.length
        newOrderSummary['locations'] = locationList.length
        setOrderSummary(newOrderSummary);
    }
    
    function handleRegisterOrder(){
        let invPlanCopy = JSON.parse(JSON.stringify(inventoryPlanning));
        var newOrderList = invPlanCopy.filter(function (s) {
            if(s.orderQuantityPallets == 0 || s.orderQuantityPallets == ""){
                return false;
            }
            else {
                return true
            }
           })
        setOrderList(newOrderList);
        updateOrderSummary();
        setOrderModalOpen(true);
    }
    function updateLeadtime(event){
        const {value} = event.target;         
        if (isNaN(value)){return;}
        const validateValue = /^[0-9]*$/;
        const valueOK = validateValue.test(value);
        if (!valueOK){console.log("tegn er ikke tillat"); return;}
        if (value.toString().charAt(0) === "0"){console.log("kan ikke starte med 0"); return;}


        let copy = JSON.parse(JSON.stringify(orderSummary));
        copy.leadtime = value;
        setOrderSummary(copy)
    }
    function updateSupplierGuess(event){
        const {value} = event.target;         
        let copy = JSON.parse(JSON.stringify(orderSummary));
        copy.supplier_id_guess = value;
        setOrderSummary(copy)
    }
    function updateLocationGuess(event){
        const {value} = event.target;         
        let copy = JSON.parse(JSON.stringify(orderSummary));
        copy.location_id_guess = value;
        setOrderSummary(copy)
    }

    function handleUpdateCartons(product_id, event)
    {
        const {value} = event.target;         
        if (isNaN(value)){return;}
        const validateValue = /^[0-9]*$/;
        const valueOK = validateValue.test(value);
        if (!valueOK){console.log("tegn er ikke tillat"); return;}
        if (value.toString().charAt(0) === "0"){console.log("kan ikke starte med 0"); return;}

        var newInvPlan = []
        for (var p in inventoryPlanning){
            if (inventoryPlanning[p].id == product_id){
                inventoryPlanning[p].orderQuantity = value;
                inventoryPlanning[p].orderQuantityPallets = value / inventoryPlanning[p].cartons_per_pallet_inbound;

            }
            newInvPlan.push(inventoryPlanning[p])
        }
        setInventoryPlanning(newInvPlan);
    }


    function handleUpdatePallets(product_id, event)
    {
        const {value} = event.target;         
        if (isNaN(value)){return;}
        const validateValue = /^[0-9]*$/;
        const valueOK = validateValue.test(value);
        if (!valueOK){console.log("tegn er ikke tillat"); return;}
        if (value.toString().charAt(0) === "0"){console.log("kan ikke starte med 0"); return;}

        var newInvPlan = []
        for (var p in inventoryPlanning){
            if (inventoryPlanning[p].id == product_id){
                inventoryPlanning[p].orderQuantityPallets = value;
                inventoryPlanning[p].orderQuantity = value * inventoryPlanning[p].cartons_per_pallet_inbound;
            }
            newInvPlan.push(inventoryPlanning[p])
        }
        setInventoryPlanning(newInvPlan);
    }


    function handleUpdatePrice(product_id, event) {
        let value = event.target.value;
        const validateValue = /^[0-9]*\.?[0-9]*$/;
        if (!validateValue.test(value)) {
            return;
        }
        if (value.startsWith('0') && value.length > 1 && value.charAt(1) !== '.') {
            return;
        }
            const newInvPlan = orderList.map(p =>
                p.id == product_id ? { ...p, price: value } : p
            );
            setOrderList(newInvPlan);
        
    }
    function handleUpdatePriceBlur(product_id, event){
        let value = event.target.value;
        let newValue = value;
        if (newValue.endsWith('.')) {
            newValue = newValue.slice(0, -1);
        }
        const newInvPlan = orderList.map(p =>
            p.id == product_id ? { ...p, price: newValue } : p
        );
        setOrderList(newInvPlan);

    }

    function handleUpdateCurrency(product_id, event) {
        let value = event.target.value;
        const newInvPlan = orderList.map(p =>
            p.id == product_id ? { ...p, currency: value } : p
        );
        setOrderList(newInvPlan);
        
    }
    function postOrder(){
        let payload = {
            'lines': [],
            'supplier_id': orderSummary.supplier_id_guess,
            'location_id': orderSummary.location_id_guess,
            'date_arrival': new Date(Date.now() + orderSummary.leadtime * 86400000).toISOString().split('T')[0],
            'date_order': new Date(Date.now()).toISOString().split('T')[0],
            'comment_internal': internalComment,
            'comment_external': externalComment
        }

        for (let ol in orderList){
            let o = orderList[ol]
            let newLine = {
                'product_id': o.id,
                'quantity_units': o.orderQuantity * o.pieces_per_quantity,
                'quantity_cartons': o.orderQuantity,
                'quantity_pallets': o.orderQuantityPallets,
                'est_rak': Math.round(o.avg_råk_per_carton * o.orderQuantity),
                'est_transport': Math.round(o.avg_transport_cost_per_carton * o.orderQuantity),
                'est_price': Math.round(o.avg_purchase_price_per_carton * o.orderQuantity),
                'assumed_carton_price': o.price,
                'assumed_currency': o.currency
            }
            payload.lines.push(newLine)
        }

        // console.log(payload)
        fetch('./api/createinboundorder', {
            method: "POST",
            body: JSON.stringify(payload),
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            })
        })
        .then((response) => {
            if(response.ok) {
                // handleCloseModal();
                // setShowToast(true);
                // setIsLoading(false)
                setPostOrderSuccess(true);
                return response.json();
            } else {
                setPostOrderSuccess(false);
                throw new Error('Network response was not ok ' + response.statusText);
            }
        }) .then((data) => {   
            handleCloseOrderModal();
            handleResetPallets();
            setResultModal(true);
            setPostOrderResponse(data);
            console.log(data)
        })
        .catch((error) => {
            handleCloseOrderModal();
            handleResetPallets();
            // setIsLoading(false)
            // setErrorModal(true);
            // setErrorMsg(error);
            console.error('There was a problem with the fetch operation:', error);
        })
    }


    function handleResetPallets(){
        let invPlanCopy = JSON.parse(JSON.stringify(inventoryPlanning));
        for (var i in invPlanCopy){
            invPlanCopy[i].orderQuantity = "";
            invPlanCopy[i].orderQuantityPallets = "";
        }
        setInventoryPlanning(invPlanCopy);
    }

    function handleDistributePallets(){
        let invPlanCopy = JSON.parse(JSON.stringify(inventoryPlanning));
        var newList = invPlanCopy.filter(function (s) {
            if(s.orderQuantityPallets == 0 || s.orderQuantityPallets == ""){
                return false;
            }
            else {
                return true
            }
           })
          
           // Function to calculate days until a product runs out
            const calculateDaysUntilRunOut = (product) => {
                const dailyConsumption = product.sum_actual_quantity_last_90_days / 90; // Daily consumption
                const inventoryDays = product.max_inventory_quantity / dailyConsumption; // Days inventory will last
                const orderDays = (product.orderQuantityPallets * product.cartons_per_pallet_inbound) / dailyConsumption; // Days added by order
                return Math.max(inventoryDays, product.order_lead_time_in_days) + orderDays;
            };

            // Function to balance order quantities
            const balanceOrderQuantities = (newList) => {
                // Calculate the total number of pallets
                const totalPallets = newList.reduce((sum, product) => sum + parseInt(product.orderQuantityPallets, 10), 0);

                // Reset all pallets to 0 for redistribution
                newList.forEach(product => product.orderQuantityPallets = 0);

                // Redistribute pallets
                for (let i = 0; i < totalPallets; i++) {
                    // Find the product with the fewest days left
                    const productWithFewestDays = newList.reduce((minProduct, currentProduct) => {
                        const currentDays = calculateDaysUntilRunOut(currentProduct);
                        const minDays = calculateDaysUntilRunOut(minProduct);
                        return currentDays < minDays ? currentProduct : minProduct;
                    });

                    // Add one pallet to the product with the fewest days left
                    productWithFewestDays.orderQuantityPallets++;
                }

                // Calculate final days for all products
                

                for (let i in newList){
                    newList[i]['orderQuantity'] = newList[i]['orderQuantityPallets'] * newList[i]['cartons_per_pallet_inbound']
                }
                return newList;
            };


            // Adjust the orderQuantityPallets
            balanceOrderQuantities(newList);



          

            setInventoryPlanning(invPlanCopy);

    }

    useEffect(() => {
        if(!token) return;
        setLoading(true);
        getInventoryPlanning();
        getAllProducts()
        getAllSuppliers()
        getAllPickupLocations()
        getInboundOrders()
        setLoading(false);
    },[token])


    

    return(
        <div>
            <AuthenticatedTemplate>

|           {inboundOrders &&
                <Container className="mt-5">
                    <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Inbound orders</Accordion.Header>
                                <Accordion.Body>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Inbound Order ID</th>
                                                <th>Supplier</th>
                                                <th>Pick-up location</th>
                                                <th>Arrival date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inboundOrders.map(i => {
                                                        return(
                                                            <tr>
                                                                <td>IO-{i.id}</td>
                                                                <td>{suppliers.find(sup => sup.id === i.supplier_id).name}</td>
                                                                <td>{pickUpLocations.find(loc => loc.id === i.location_id).name}</td>
                                                                <td>{new Date(i.date_arrival).toISOString().split('T')[0]}</td>
                                                            </tr>
                                                        )
                                                        
                                                        })}
                                        </tbody>
                                    </Table>
                            
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                </Container>
            }
            

            <Container className="mt-5">
                <Row>
                    <h2 className="px-0 my-0">Inventory planning</h2>                                                           
                {error.status && <p>{error.message}</p>} 
                </Row>
                
                { !error.status &&
                <Row className="mt-4 d-flex">
                    <Col style={{padding:0}}>
                        <Button variant="secondary" onClick={handleDistributePallets}>Distribute pallets</Button>                
                        <Button className="mx-3" variant="secondary" onClick={handleResetPallets}>Reset pallets</Button>                
                    </Col>
                    <Col style={{padding:0}} className="justify-content-end d-flex">
                        <Button className="" variant="secondary" onClick={handleRegisterOrder}>Register order</Button>                

                    </Col>
                </Row>}
                
                { !error.status && 
                
                <Row className="mt-4">
                <Card className="mb-3 p-0 col-12">
                    <Card.Header>
                        <FilterMultipleSelect name_of_filtered="supplier" selected={selectedSuppliers} complete_object={suppliers} onChange={(return_data) => setSelectedSuppliers(return_data)}></FilterMultipleSelect>
                        <div className="mt-2"></div>
                        <FilterMultipleSelect name_of_filtered="pick-up location" selected={selectedPickUpLocations} complete_object={pickUpLocations} onChange={(return_data) => setSelectedPickUpLocations(return_data)}></FilterMultipleSelect>
                        <div className="mt-2"></div>
                        <FilterMultipleWithLabels filterByFields={filterByFields} filterByLabels={filterByLabels} name_of_filtered="product" selected={selectedProducts} complete_object={products} onChange={(return_data) => setSelectedProducts(return_data)} />
                        <Form.Check
                                        className="mt-2"
                                        type="checkbox"
                                        id={`default-active`}
                                        label={`Only active products`}
                                        checked={filterActive}
                                        onChange={handleFilterActive}
                                    />
                        <Form>
                            <div className="mb-3">
                                <Form.Check
                                    inline
                                    label="All"
                                    name="group1"
                                    type="radio"
                                    id={`inline-radio-4`}
                                    value="All"
                                    checked={selectedOption === "All"} // Control checked state
                                    onChange={handleChange} // Update state on change
                                />
                                <Form.Check
                                    inline
                                    label="< 30 days"
                                    name="group1"
                                    type="radio"
                                    id={`inline-radio-1`}
                                    value="30"
                                    checked={selectedOption === "30"} // Control checked state
                                    onChange={handleChange} // Update state on change
                                />
                                <Form.Check
                                    inline
                                    label="< 60 days"
                                    name="group1"
                                    type="radio"
                                    id={`inline-radio-2`}
                                    value="60"
                                    checked={selectedOption === "60"} // Control checked state
                                    onChange={handleChange} // Update state on change
                                />
                                <Form.Check
                                    inline
                                    label="< 90 days"
                                    name="group1"
                                    type="radio"
                                    id={`inline-radio-3`}
                                    value="90"
                                    checked={selectedOption === "90"} // Control checked state
                                    onChange={handleChange} // Update state on change
                                />
                            </div>
                        </Form>
                    </Card.Header> 
                    <Card.Body className="p-0 m-0">
                    <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                            height="80" 
                            width="80" 
                            radius="9"
                            color="#3e3e3e" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={loading}
                            />
                        </div>
                        { inventoryPlanning.length > 0 &&
                            <Table striped bordered hover responsive className="m-0">
                                <thead>
                                    <tr>                                    
                                    <th className="text-center fw-bold">Art. nr</th>
                                    <th className="text-center fw-bold">Name</th>
                                    <th className="text-center fw-bold">Loc.</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Current inventory</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Cons. / day</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Days left</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Lead time</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>D-pak</th>
                                    <th>Pallets</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Minimum order</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Est. last day</th>
                                    <th className="text-center fw-bold" style={{whiteSpace: 'nowrap'}}>Est. best before</th>

                                    {/* <th className="text-center fw-bold w-auto" style={{whiteSpace: 'nowrap'}}>AVG per {intervals[salesVolumeFilter]}</th>    */}
                                    {/* <th className="text-center fw-bold"> {intervals[salesVolumeFilter]}s of inventory</th>                                    */}
                                    
                                    </tr>
                                </thead>
                                <tbody style={{"cursor": "pointer"}}>
                                    {inventoryPlanning.filter(function (s) {
                                                    if(selectedProducts.length == 0){
                                                        return true;
                                                    }
                                                    else {
                                                        for (var sp in selectedProducts) {
                                                            if (selectedProducts[sp].id == s.id) {
                                                                return true
                                                            }
                                                        }
                                                        return false
                                                    }
                                                   })
                                                    .filter(function (q) {
                                                    if(selectedSuppliers.length == 0){
                                                        return true;
                                                    }
                                                    else {
                                                        for (var ss in selectedSuppliers) {
                                                            if (selectedSuppliers[ss].id == q.supplier_id) {
                                                                return true
                                                            }
                                                        }
                                                        return false
                                                    }
                                                })
                                                .filter(function (q) {
                                                    if(selectedPickUpLocations.length == 0){
                                                        return true;
                                                    }
                                                    else {
                                                        for (var ss in selectedPickUpLocations) {
                                                            if (selectedPickUpLocations[ss].id == q.pickup_location_id) {
                                                                return true
                                                            }
                                                        }
                                                        return false
                                                    }
                                                }).filter(function (p) {
                                                    if(!filterActive){
                                                        return true;
                                                    } else {
                                                        return p.active == 1
                                                    }
                                                }).filter(function (i) {
                                                    if(selectedOption == "All") {
                                                        return true;
                                                    } else {
                                                        return (i.max_inventory_quantity / ((i.sum_actual_quantity_last_90_days) / 90)) < selectedOption
                                                    }
                                                })
                                                .map(i => {
                                                return(
                                                    <tr key={i.id}>
                                                        <td className="text-center">{i.article_nr}<br></br></td>
                                                        <td style={{"padding": "4px 10px"}}>{i.name}</td>
                                                        <td style={{"padding": "4px 10px"}}>{i.pickup_location_name}</td>
                                                        <td className="text-end" style={{"padding": "4px 10px"}}>{i.max_inventory_quantity}</td>
                                                        <td className="text-end" style={{"padding": "4px 10px"}}>{Math.round(i.sum_actual_quantity_last_90_days / 90 * 100) / 100}</td>
                                                        {i.sum_actual_quantity_last_90_days != 0 && 
                                                            <td className="text-end" style={{"padding": "4px 10px"}}>{Math.round((i.max_inventory_quantity / ((i.sum_actual_quantity_last_90_days) / 90)) * 100) / 100}</td>
                                                        }
                                                        {i.sum_actual_quantity_last_90_days == 0 && 
                                                            <td className="text-end" style={{"padding": "4px 10px"}}>0</td>
                                                        }
                                                        <td className="text-end" style={{"padding": "4px 10px"}}>{i.order_lead_time_in_days}</td>
                                                        <td>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            // value={props.amount}
                                                             value={i.orderQuantity}
                                                            onChange={(event) =>
                                                                handleUpdateCartons(
                                                                i.id,
                                                                event
                                                                )
                                                            }
                                                            placeholder="#"
                                                            aria-label="Number of cartons"
                                                            
                                                            />
                                                        </td>
                                                        <td>
                                                        <input
                                                            type="text"
                                                            className="form-control text-center"
                                                            // value={props.amount}
                                                             value={i.orderQuantityPallets}
                                                            onChange={(event) =>
                                                                handleUpdatePallets(
                                                                i.id,
                                                                event
                                                                )
                                                            }
                                                            placeholder="#"
                                                            aria-label="Number of cartons"
                                                            
                                                            />
                                                        </td>
                                                        
                                                        <td className="text-end" style={{"padding": "4px 10px"}}>{i.minimum_order_in_pallets}</td>

                                                        {(i.sum_actual_quantity_last_90_days != 0) &&
                                                            <>
                                                            <td className="text-end" style={{"padding": "4px 10px"}}>{new Date(Date.now() + (((Math.max((i.max_inventory_quantity / (i.sum_actual_quantity_last_90_days / 90)), i.order_lead_time_in_days) + (i.orderQuantity / (i.sum_actual_quantity_last_90_days / 90)))) * 86400000)).toISOString().split('T')[0]}</td>
                                                            </>
                                                        }
                                                        {i.sum_actual_quantity_last_90_days == 0 && 
                                                            <td className="text-end" style={{"padding": "4px 10px"}}></td>
                                                        }
                                                        {i.best_before_days_from_order &&
                                                        <td>{new Date(Date.now() + i.best_before_days_from_order * 8640000).toISOString().split('T')[0]}</td>
                                                        }
                                                        {!i.best_before_days_from_order &&
                                                        <td></td>
                                                        }


                                                    </tr>
                                                )
                                            })}



                                    

                                    
                                </tbody>
                            </Table>
                        }
                    </Card.Body>
                </Card>
                </Row>}
            </Container>


            <Modal fullscreen={true} show={orderModalOpen} onHide={handleCloseOrderModal}>
            {/* <Modal size="xl" show={orderModalOpen} onHide={handleCloseOrderModal}> */}
                <Modal.Header closeButton>
                    <Modal.Title>New order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {orderList &&
                    <>

                    

                    <Table style={{ maxWidth: "2000px"}}>
                        <thead>
                            <tr>
                                <th colSpan="7"></th>
                                <th colSpan="3" style={{"backgroundColor": "rgb(244, 244, 244)", "textAlign": "center"}}>Cost estimates (NOK)</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th>Supplier</th>
                                <th>Location</th>
                                <th>Art. nr</th>
                                <th>Name</th>
                                <th className="text-end px-4">Units</th>
                                <th className="text-end px-4">Cartons</th>
                                <th className="text-end px-4">Pallets</th>
                                <th className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>RÅK</th>
                                <th className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>Transport</th>
                                <th className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>Price</th>
                                <th className="text-center px-4">Carton price</th>
                                <th className="text-center px-4">Currency</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList.map(o => {
                                                return(
                                                    <tr>
                                                        <td>{o.supplier_name}</td>
                                                        <td>{o.pickup_location_name}</td>
                                                        <td>{o.article_nr}</td>
                                                        <td>{o.name}</td>
                                                        <td className="text-end px-4">{(o.orderQuantity * o.pieces_per_quantity).toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                                        <td className="text-end px-4">{o.orderQuantity.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                                        <td className="text-end px-4">{o.orderQuantityPallets.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                                        <td className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>{Math.round(o.avg_råk_per_carton * o.orderQuantity).toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                                        <td className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>{Math.round(o.avg_transport_cost_per_carton * o.orderQuantity).toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                                        <td className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>{Math.round(o.avg_purchase_price_per_carton * o.orderQuantity).toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                                        <td className="text-end px-4">
                                                            <input
                                                                    type="text"
                                                                    className="form-control text-center"
                                                                    // value={props.amount}
                                                                    value={o.price}
                                                                    onChange={(event) =>
                                                                        handleUpdatePrice(
                                                                        o.id,
                                                                        event
                                                                        )
                                                                    }
                                                                    onBlur={(event) =>
                                                                        handleUpdatePriceBlur(
                                                                            o.id,
                                                                            event
                                                                        )
                                                                    }
                                                                    placeholder="#"
                                                                    aria-label="Price"
                                                                    
                                                                    />
                                                            </td>
                                                        <td className="text-center px-4">
                                                        <Form.Select
                                                            aria-label="Select a supplier"
                                                            onChange={(event) => handleUpdateCurrency(o.id, event)}
                                                            value={o["currency"]}
                                                            >
                                                                <option value="EUR">EUR</option>
                                                                <option value="USD">USD</option>
                                                                <option value="GBP">GBP</option>
                                                                <option value="NOK">NOK</option>
                                                                <option value="SEK">SEK</option>
                                                                <option value="DKK">DKK</option>
                                                        </Form.Select>
                                                            </td>
                                                    </tr>
                                                )}
                            )}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="text-center">{orderSummary.suppliers}</td>
                                <td className="text-center">{orderSummary.locations}</td>
                                <td></td>
                                <td className="text-center">{orderSummary.articles}</td>
                                <td className="text-end px-4">{orderSummary.units.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                <td className="text-end px-4">{orderSummary.cartons.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                <td className="text-end px-4">{orderSummary.pallets.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                <td className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>{orderSummary.rak.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                <td className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>{orderSummary.transport.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                                <td className="text-end px-4" style={{"backgroundColor": "rgb(244, 244, 244)"}}>{orderSummary.price.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <Table style={{ marginTop: "60px", maxWidth: "400px"}}>
                        <thead>
                            <tr>
                                <th>Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Supplier</td>
                                <td>

                                    <Form.Select
                                        aria-label="Select a supplier"
                                        onChange={(event) => updateSupplierGuess(event)}
                                        value={orderSummary["supplier_id_guess"]}
                                        >
                                        {suppliers.map((supplier) => (
                                            <option key={supplier.id} value={supplier.id}>
                                            {supplier.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </td>
                                
                            </tr>
                            <tr>
                                <td>Pick-up location</td>
                                <td>
                                <Form.Select
                                    aria-label="Select a location"
                                    onChange={(event) => updateLocationGuess(event)}
                                    value={orderSummary["location_id_guess"]}
                                    >
                                    {pickUpLocations.map((l) => (
                                        <option key={l.id} value={l.id}>
                                        {l.name}
                                        </option>
                                    ))}
                                </Form.Select>

                                </td>
                            </tr>
                            <tr>
                                <td>Order lead time</td>
                                    <td>

                                        <input
                                                                    type="text"
                                                                    className="form-control text-center"
                                                                    // value={props.amount}
                                                                    value={orderSummary.leadtime}
                                                                    onChange={(event) =>
                                                                        updateLeadtime(
                                                                        event
                                                                        )
                                                                    }
                                                                    placeholder="#"
                                                                    aria-label="Number of cartons"
                                                                    
                                                                    />
                                </td>

                            </tr>
                            <tr>
                                <td>Est. date of arrival</td>
                                <td className="text-center">{new Date(Date.now() + orderSummary.leadtime * 86400000).toISOString().split('T')[0]}</td>
                            </tr>
                            
                        </tbody>
                    </Table>
                    <Form>
                        <Form.Label>Internal comment</Form.Label>
                        <Form.Control value={internalComment} onChange={(event) =>
                                                                        setInternalComment(
                                                                        event.target.value
                                                                        )
                                                                    } as="textarea" />

                        <Form.Label>External comment</Form.Label>
                        <Form.Control value={externalComment} onChange={(event) =>
                                                                        setExternalComment(
                                                                        event.target.value
                                                                        )
                                                                    } as="textarea" />
                    </Form>

                    </>
                    }
                    
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={postOrder}>Save order</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={resultModal} size="lg" onHide={handleCloseResultModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{postOrderResponse && 
                        <>
                            Order IO-{postOrderResponse.id} has been registered
                        </>
                        }</Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                    {postOrderResponse &&
                        <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Information</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Order reference</td>
                                    <td>IO-{postOrderResponse.id}</td>
                                </tr>
                                <tr>
                                    <td>Supplier</td>
                                    <td>{suppliers.find(sup => sup.id === postOrderResponse.supplier_id).name}</td>
                                </tr>
                                <tr>
                                    <td>Pick-up location</td>
                                    <td>{pickUpLocations.find(loc => loc.id === postOrderResponse.location_id).name}</td>
                                </tr>
                                <tr>
                                    <td>Order data</td>
                                    <td>{postOrderResponse.date_order}</td>
                                </tr>
                                <tr>
                                    <td>Arrival data</td>
                                    <td>{postOrderResponse.date_arrival}</td>
                                </tr>
                                
                            </tbody>
                        </Table>
                        <br></br>

                          <Table>
                                <thead>
                                    <tr>
                                        <th>Art. nr</th>
                                        <th>Name</th>
                                        <th>Cartons</th>
                                        <th>Price</th>
                                        <th>Currency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {postOrderResponse.lines.map(l => {
                                                        return(
                                                            <tr>
                                                                <td>{products.find(product => product.id === l.product_id).article_nr}</td>
                                                                <td>{products.find(product => product.id === l.product_id).name}</td>
                                                                <td>{l.quantity_cartons}</td>
                                                                <td>{l.assumed_carton_price}</td>
                                                                <td>{l.assumed_currency}</td>
                                                            </tr>
                                                        )
                                                    })}
                                </tbody>
                            </Table>
                                                    <br></br>
                            <div>
                                <strong>External comment</strong>
                                <p>{postOrderResponse.comment_external}</p>
                                <strong>Internal comment</strong>
                                <p>{postOrderResponse.comment_internal}</p>

                            </div>
                            <div>
                                <strong>PDF</strong>
                                <PDFGenerator
                                    postOrderResponse={postOrderResponse}
                                    suppliers={suppliers}
                                    pickUpLocations={pickUpLocations}
                                    products={products}
                                />
                            </div>
                        </>
                        
                    }
                </Modal.Body>

            </Modal>
            </AuthenticatedTemplate>
        </div>
    )
}