import './App.css';
import './css/general.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import React from 'react';
import { useMemo } from 'react';

/* MSAL */
import { MsalProvider, useMsal } from '@azure/msal-react';
import { CustomNavigationClient } from "./util/NavigationClient";
import { useIsAuthenticated } from '@azure/msal-react';
import { useFetchUserGroups } from './util/fetchUserGroups';

/* Components */
import ProtectedRoute from './components/ProtectedRoute';

/* Routes */
import DashboardManager from './routes/Dashboard/DashboardManager';
import CustomersManager from './routes/Customers/CustomersManager';
import SuppliersManager from './routes/Suppliers/SuppliersManager';
import PickupLocationsManager from './routes/PickupLocations/PickupLocationsManager';
import CustomerPricesManager from './routes/CustomerPrices/CustomerPricesManager';
import AllergensManager from './routes/Allergens/AllergensManager';
import CarriersManager from './routes/Carriers/CarriersManager';
import SubCustomerAccessManager from './routes/SubCustomerAccess/SubCustomerAccessManager';
import Products from './routes/Products/Products';
import ExampleMsal from './routes/ExampleMsal';
import { PageLayout } from './components/PageLayout'; //Hoved komponent for rendring av children
// import Inventory from './routes/Inventory/Inventory';
import FileNotFound from './routes/FileNotFound/FileNotFound';
import Login from './routes/Login/Login';
import MyOrders from './routes/OrderHistory/MyOrders';
import SalesVolume from './routes/SalesVolume/SalesVolume';
//import FOrderManager from './routes/Freeze_Order/FOrderManager';
//import DOrderManager from './routes/Dry_order/DOrderManager';
// import OrderHistoryManager from './routes/OrderHistory/OrderHistoryManager';
// import InventoryManager from './routes/Inventory/InventoryManager';
import InventoryValue from './routes/InventoryValue/InventoryValue';  


// MSAL IMPORTS 
import Test from './routes/test/Test';
import Logout from './routes/Logout/Logout';
import Inventory from './routes/Inventory/Inventory';
import Inbound from './routes/Inbound/Inbound';
import InboundOverview from './routes/Inbound/InboundOverview';

import ProductBatchStatus from './routes/ProductBatchStatus/ProductBatchStatus';
import OrderManager from './routes/order/OrderManager';
import InboundOverview2 from './routes/Inbound/InboundOverview2';
import InventoryPlanning from './routes/InventoryPlanning/InventoryPlanning';




function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  

  return (
    <React.StrictMode>
      <MsalProvider instance={pca}>
          <PageLayout>
                <Views />
          </PageLayout>
      </MsalProvider>
      </React.StrictMode>
  );
}

function AuthRedirect() {
   const navigate = useNavigate();
   const isAutenticated = useIsAuthenticated(); 

   if(!isAutenticated){
    navigate('/login') 
   }
}


function Views(){

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();
  const userGroups = useFetchUserGroups();
  /* 
  const memoizedUserGroups = useMemo(() => {
    return userGroups;
  }, []); */

  /* ----------- OLD ------------
  useEffect(() => {
    if (!activeAccount) {
      navigate('/login');
    }
  }, [activeAccount, navigate]); */


  const testGroup = ["APP_PORTAL_ADMIN"];
  const allAllowed = ["APP_PORTAL_ADMIN", "APP_PORTAL_AM", "APP_PORTAL_PM", "APP_PORTAL_IS", "APP_PORTAL_ES"];
  const PMAboveGroup = ["APP_PORTAL_ADMIN",  "APP_PORTAL_PM"];
  const AMAboveGroup = ["APP_PORTAL_ADMIN", "APP_PORTAL_AM", "APP_PORTAL_PM"];
  const StoreGroup = ["APP_PORTAL_ADMIN", "APP_PORTAL_AM", "APP_PORTAL_PM", "APP_PORTAL_IS", "APP_PORTAL_ES"]

  
  return (
     
    <Routes>

      <Route path="/login" element={<Login />} />
      <Route path="/" element={<DashboardManager/>}/>
      <Route path="/orderfrozen" 
        element={
        <ProtectedRoute allowedGroups={allAllowed} userGroups={userGroups}>
          <OrderManager natureOfCargo="Frozen" />
        </ProtectedRoute>} />

      <Route path="/logout" element={<Logout />}/>

      <Route path="/orderdry" 
        element={<ProtectedRoute allowedGroups={allAllowed} userGroups={userGroups}>
          <OrderManager natureOfCargo="Dry" />
        </ProtectedRoute>} />

        <Route path="/test" 
        element={<ProtectedRoute allowedGroups={allAllowed} userGroups={userGroups}>
          <Test />
        </ProtectedRoute>} />

      <Route path="/products" 
      element={
        <ProtectedRoute allowedGroups={AMAboveGroup} userGroups={userGroups}>
          <Products />
        </ProtectedRoute>
      } />
      <Route path="/orderhistory" element={
        <ProtectedRoute allowedGroups={allAllowed} userGroups={userGroups}>
          <MyOrders />
        </ProtectedRoute>
      } />
      <Route path="/customers" 
      element={
        <ProtectedRoute allowedGroups={AMAboveGroup} userGroups={userGroups}>
          <CustomersManager />
        </ProtectedRoute>
      } />
      <Route path="/salesvolume" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <SalesVolume />
        </ProtectedRoute>
      } />
      <Route path="/suppliers" 
      element={
        <ProtectedRoute allowedGroups={AMAboveGroup} userGroups={userGroups}>
          <SuppliersManager />
        </ProtectedRoute>
      } />
      <Route path="/pickuplocations" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <PickupLocationsManager />
        </ProtectedRoute>
      } />
      <Route path="/carriers" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <CarriersManager />
        </ProtectedRoute>
      } />
      <Route path="/allergens" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <AllergensManager />
        </ProtectedRoute>
      } />
      <Route path="/inbound" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <InboundOverview2 />
        </ProtectedRoute>
      } />

      <Route path="/productbatchstatus" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <ProductBatchStatus />
        </ProtectedRoute>
      } />
      <Route path="/customerprices" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <CustomerPricesManager />
        </ProtectedRoute>
      } />
      <Route path="/inventory" 
      element={
        <ProtectedRoute allowedGroups={AMAboveGroup} userGroups={userGroups}>
          <Inventory />
        </ProtectedRoute>
      } />
      <Route path="/inventoryvalue" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <InventoryValue />
        </ProtectedRoute>
      } />
      <Route path="/inventoryplanning" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <InventoryPlanning />
        </ProtectedRoute>
      } />
      <Route path="/subcustomeraccess" 
      element={
        <ProtectedRoute allowedGroups={PMAboveGroup} userGroups={userGroups}>
          <SubCustomerAccessManager />
        </ProtectedRoute>
      }/>
      <Route path="*" element={<FileNotFound />}></Route>
    </Routes>
  ) 
}

export default App;

  /* ID Oversikt for bygging
  1 - External store
  2 - Internal store
  3 - AM
  4 - PM
  5 - Admin
  */