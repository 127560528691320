import { useEffect } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

export default function OrderListElement(props) {
    
    const dfd = require("danfojs")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const exportTripletex = () => {
        var data = []
        for (var line in props.o.lines) {
            var lineData = {
                "Order ID": "OP" + props.o.id.toString(), 
                "Order date": props.o.date_of_order.split(" ")[0],
                "Pick-up date": props.o.date_of_pickup.split(" ")[0],
                "Product": props.o.lines[line].product.backstubeName,
                "Ordered quantity": props.o.lines[line].quantity,
                "Shipped quantity": props.o.lines[line].actualQuantity,
            }
            data.push(lineData)
        }
        var df = new dfd.DataFrame(data)
        df.print()
        const xlsx = dfd.toExcel(df, { fileName: "Order " + props.o.id.toString() + ".xlsx", download: true})
    }

    return(
        <AuthenticatedTemplate>
        <tr>
            <td className="text-center" onClick={handleShow}>OP{props.o.id}</td>
            <td className="text-center" onClick={handleShow}>{props.o.date_of_order.split(" ")[0]}</td>
            <td className="text-center" onClick={handleShow}>{props.o.date_of_pickup.split(" ")[0]}</td>
            <td className="px-2" onClick={handleShow}>{props.o.sub_customer[0].customerName}</td>
            <td className="px-2" onClick={handleShow}>{props.o.sub_customer[0].subCustomerName}</td>
            <td className="text-center" onClick={handleShow}>{props.o.lines.length}</td>
            <td className="text-center" onClick={exportTripletex}><Button variant="secondary">Export</Button></td>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Order {props.o.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Header>Order information</Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <tr>
                                    <td>Customer</td>
                                    <td>{props.o.sub_customer[0].customerName}</td>
                                </tr>
                                <tr>
                                    <td>Sub customer</td>
                                    <td>{props.o.sub_customer[0].subCustomerName}</td>
                                </tr>
                                <tr>
                                    <td>Order date</td>
                                    <td>{props.o.date_of_order.split(" ")[0]}</td>
                                </tr>
                                <tr>
                                    <td>Pick-up-date</td>
                                    <td>{props.o.date_of_pickup.split(" ")[0]}</td>
                                </tr>
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>
                            Order lines
                        </Card.Header>
                        <Card.Body>
                            <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th style={{background: '#f0f0f0'}} className="text-center" colspan="2">Boxes</th>
                                <th className="text-center" colspan="2">Units</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th>Line ID</th>
                                <th>Product</th>
                                <th style={{background: '#f0f0f0'}} className="text-center">Ordered</th>
                                <th style={{background: '#f0f0f0'}} className="text-center">Shipped</th>
                                <th className="text-center">Ordered</th>
                                <th className="text-center">Shipped</th>
                            </tr>
                        </thead>
                        
                            <tbody>
                                {props.o.lines.map((ol) => {
                                    return <tr key={ol.orderLineId}><td>{ol.orderLineId}</td><td>{ol.product.backstubeName}<br />GTIN: {ol.product.gtin}</td><td style={{background: '#f0f0f0'}} className="text-center">{ol.quantity}</td><td style={{background: '#f0f0f0'}} className="text-center">{ol.actualQuantity}</td><td className="text-center">{ol.product.piecesPerQuantity * ol.quantity}</td><td className="text-center">{typeof ol.actualQuantity == typeof 0 && ol.product.piecesPerQuantity * ol.actualQuantity}</td></tr>
                                })}
                                
                        


                            </tbody>
                        </Table>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </tr>
        </AuthenticatedTemplate>
    )
}